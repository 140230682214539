import React from "react";
import { Icon } from "react-feather";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  icon?: Icon;
  iconPosition?: "start" | "end";
  iconColor?: "red" | "green" | "grey";
  iconFill?: boolean;
  buttonStyle?: "regular" | "action" | "alert" | "flush";
}

export function Button({
  label = "Okay",
  icon = void 0,
  iconPosition = "start",
  iconColor = "grey",
  iconFill = false,
  buttonStyle = "regular",
  ...rest
}: ButtonProps) {
  const EndIcon = iconPosition === "end" ? icon : null;

  // Updated button styles to match the image
  const baseStyles =
    "flex items-center justify-center gap-2 px-6 py-2 rounded-full font-medium text-white transition-transform transform hover:scale-105 shadow-md";
  const styles = {
    regular: "bg-red-500 hover:bg-red-600",
    action: "bg-[#126f76] hover:bg-[#0e5a5d]",
    alert: "bg-red-500 hover:bg-red-600",
    flush: "bg-transparent text-blue-500 hover:bg-blue-100",
  };
  const iconColors = {
    red: "text-red-500",
    green: "text-green-500",
    grey: "text-gray-500",
  };

  return (
    <button
      data-component="Button"
      className={`${baseStyles} ${styles[buttonStyle]} ${rest.disabled ? "opacity-50 cursor-not-allowed" : ""
        }`}
      {...rest}
    >
      {label && <span>{label}</span>}
      {EndIcon && (
        <span className={`${iconColors[iconColor]} ${iconFill ? "fill-current" : ""}`}>
          <EndIcon />
        </span>
      )}
    </button>
  );
}
