const LOCAL_RELAY_SERVER_URL: string =
  `wss://harmoni-relay-485783353493.us-central1.run.app` || '';

import { useEffect, useRef, useCallback, useState, useMemo } from 'react';
import { RealtimeClient } from '@openai/realtime-api-beta';
import { ItemType, TurnDetectionServerVadType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { WavRenderer } from '../utils/wav_renderer';
import { ChevronDown, X, Zap, } from 'react-feather';
import { Button } from '../components/button/Button';
import { Toggle } from '../components/toggle/Toggle';
import { useLocation } from 'react-router-dom';
import Header from '../components/header';
import { MicButton } from '../components/mic-button/index.jsx';
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../firebase.js';
import { useUser } from '../context/AppContext';
import { useTimer } from '../components/count-down/count';

interface WavStreamPlayerWithGain extends WavStreamPlayer {
  gainNode?: GainNode;
}
interface RealtimeEvent {
  time: string;
  source: 'client' | 'server';
  count?: number;
  event: { [key: string]: any };
}

export function ConsolePage() {

  const apiKey = LOCAL_RELAY_SERVER_URL
    ? ''
    : localStorage.getItem('tmp::voice_api_key') ||
    prompt('OpenAI API Key') ||
    '';
  if (apiKey !== '') {
    localStorage.setItem('tmp::voice_api_key', apiKey);
  }
  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayerWithGain>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient(
      LOCAL_RELAY_SERVER_URL
        ? { url: LOCAL_RELAY_SERVER_URL }
        : {
          apiKey: apiKey,
          dangerouslyAllowAPIKeyInBrowser: true,
        }
    )
  );
  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);
  const eventsScrollHeightRef = useRef(0);
  const eventsScrollRef = useRef<HTMLDivElement>(null);
  const startTimeRef = useRef<string>(new Date().toISOString());
  const [items, setItems] = useState<ItemType[]>([]);
  const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);
  const [isConnected, setIsConnected] = useState(false);
  const [canPushToTalk, setCanPushToTalk] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [memoryKv, setMemoryKv] = useState<{ [key: string]: any }>({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { user } = useUser()
  const { isRunning, remainingTime, startTimer, stopTimer } = useTimer();
  const targetLang = queryParams.get("targ-lang");
  const sourceLang = queryParams.get("src-lang");

  const instructions = `
Role:
You are Harmoni. Your sole responsibility is only to provide accurate, word-for-word translations between an English-speaking doctor and a patient speaking ${targetLang}.
If english is spoken translate it to ${targetLang} only without adding anything extra and if ${targetLang} is spoken then translate it to English only without adding anything extra.

Guidelines:
1. Introducing yourself to both the doctor and the patient with these mentioned sentences:
  - For the doctor in English: “Hi, I’m Harmoni. I’ll be your translator today. Please begin speaking, and I’ll translate for you.”
  - For the patient in ${targetLang}: “Hi, I’m Harmoni. I’ll be your translator today.”

2. Translation Rules:
   • Must translate every spoken word between the doctor and the patient exactly as communicated—no additions, omissions, or changes to the meaning.
   • Must avoid adding any extra context, commentary, or explanations beyond the words spoken by either party.

3. Voice Instructions:
   • Speak in a natural, friendly voice that ensures clarity and comfort for both the doctor and the patient.

4. Clarifications:
   • If either the doctor or the patient’s message is unclear, politely ask for clarification and accurately translate their response to the other party.

5. Consistency:
   • Maintain the same level of professionalism, tone, and accuracy throughout the conversation for both the doctor and the patient.

6. Tone:
   • Be polite, empathetic, and easy to understand, ensuring clear communication and prioritizing comfort for both parties.

Reminder:
   - Your job is strictly to translate between the doctor and patient. If english is spoken translate it to ${targetLang} only without adding anything extra and if ${targetLang} is spoken then translate it to English only without adding anything extra.`;


  const connectConversation = useCallback(async () => {
    if (!isRunning) {
      startTimer()
    }
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    startTimeRef.current = new Date().toISOString();
    setIsConnected(true);
    setRealtimeEvents([]);
    setItems(client.conversation.getItems());

    await wavRecorder.begin();
    await wavStreamPlayer.connect();
    await client.connect();
    // Shimmer
    client.updateSession({
      modalities: ["text", "audio"],
      voice: "shimmer", // Set your preferred voice
      instructions: instructions,
      input_audio_transcription: {
        model: "whisper-1",
      },
    });

    //coment for testing
    client.sendUserMessageContent([
      {
        type: `input_text`,
        text: `Hi, Introduce yourself to both Doctor(English) and Patient(${targetLang})!`,
      },
    ]);

    if (client.getTurnDetectionType() === 'server_vad') {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
  }, []);

  const sanitizeItem = (item: any) => {
    const sanitizedItem = { ...item };

    // Remove unsupported custom types like Int16Array
    if (sanitizedItem.formatted?.audio) {
      delete sanitizedItem.formatted.audio;
    }

    // Transform any other unsupported types to Firestore-compatible types
    if (sanitizedItem.formatted?.file) {
      sanitizedItem.formatted.file = {
        url: sanitizedItem.formatted.file?.url || "",
      };
    }

    return sanitizedItem;
  };

  const saveConversation = async (items: any) => {
    try {
      const conversationTitle = `Conversation ${new Date().toISOString()}`;
      const conversationData = {
        title: conversationTitle,
        timestamp: new Date().toISOString(),
        items: items.map((item: any) => sanitizeItem(item)), // Sanitize each item
      };

      const user = auth.currentUser;

      if (!user) {
        throw new Error("User not authenticated");
      }

      const userDocRef = doc(db, "users", user.uid);

      // Add the sanitized conversation object to the conversations array
      await updateDoc(userDocRef, {
        conversations: arrayUnion(conversationData),
      });

      console.log("Conversation successfully added to user's conversations array");
    } catch (error) {
      console.error("Error saving conversation:", error);
      throw error;
    }
  };


  const disconnectConversation = useCallback(async () => {
    await stopTimer()
    setIsConnected(false);
    setRealtimeEvents([]);
    setItems([]);
    setMemoryKv({});

    // Ensure all transcripts are fetched before saving
    const client = clientRef.current;
    const conversationItems = client.conversation.getItems();

    await saveConversation(conversationItems);
    client.disconnect();

    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.end();

    const wavStreamPlayer = wavStreamPlayerRef.current;
    await wavStreamPlayer.interrupt();
  }, []);

  useEffect(() => {
    if (remainingTime <= 0 && isConnected && user.user_type === 'FREE') {
      disconnectConversation();
    }
  }, [remainingTime, isConnected, disconnectConversation]);

  const deleteConversationItem = useCallback(async (id: string) => {
    const client = clientRef.current;
    client.deleteItem(id);
  }, []);

  const startRecording = async () => {
    setIsRecording(true);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const trackSampleOffset = await wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      await client.cancelResponse(trackId, offset);
    }
    await wavRecorder.record((data) => client.appendInputAudio(data.mono));
  };

  const stopRecording = async () => {
    setIsRecording(false);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;

    // Pause recording and get frequencies
    await wavRecorder.pause();
    // const frequencies = wavRecorder.getFrequencies('voice');

    // Extract the frequency values
    // const frequencyValues = Object.values(frequencies.values);

    // Calculate the average amplitude
    // const averageAmplitude = frequencyValues.reduce((sum, value) => sum + value, 0) / frequencyValues.length;

    // Define a threshold for silence
    // const silenceThreshold = 0.04; // Adjust based on testing

    // Log for debugging
    // console.log(`🚀 ~ stopRecording ~ averageAmplitude: ${averageAmplitude}`);

    // Suppress response if average amplitude is below the threshold
    // if (averageAmplitude > silenceThreshold) {
    //   client.createResponse();
    // } else {
    //   console.log('Silence detected: No response created');
    // }
    client.createResponse();
  };


  const changeTurnEndType = async (value: string) => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    if (value === 'none' && wavRecorder.getStatus() === 'recording') {
      await wavRecorder.pause();
    }
    client.updateSession({
      turn_detection: value === 'none' ? null : { type: 'server_vad' },
    });
    if (value === 'server_vad' && client.isConnected()) {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
    setCanPushToTalk(value === 'none');
  };

  useEffect(() => {
    if (eventsScrollRef.current) {
      const eventsEl = eventsScrollRef.current;
      const scrollHeight = eventsEl.scrollHeight;
      // Only scroll if height has just changed
      if (scrollHeight !== eventsScrollHeightRef.current) {
        eventsEl.scrollTop = scrollHeight;
        eventsScrollHeightRef.current = scrollHeight;
      }
    }
  }, [realtimeEvents]);


  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll('[data-conversation-content]')
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [items]);


  useEffect(() => {
    // Get refs
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;
    client.updateSession({ instructions: instructions });
    client.updateSession({ input_audio_transcription: { model: 'whisper-1' } });

    client.on('realtime.event', (realtimeEvent: RealtimeEvent) => {
      setRealtimeEvents((realtimeEvents) => {
        const lastEvent = realtimeEvents[realtimeEvents.length - 1];
        if (lastEvent?.event.type === realtimeEvent.event.type) {
          lastEvent.count = (lastEvent.count || 0) + 1;
          return realtimeEvents.slice(0, -1).concat(lastEvent);
        } else {
          return realtimeEvents.concat(realtimeEvent);
        }
      });
    });
    client.on('error', (event: any) => console.error(event));
    client.on('conversation.interrupted', async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });
    client.on('conversation.updated', async ({ item, delta }: any) => {
      const items = client.conversation.getItems();
      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
      }
      if (item.status === 'completed' && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
      }
      setItems(items);
    });
    setItems(client.conversation.getItems());
    return () => {
      client.reset();
    };
  }, []);


  const [selectedLang, setSelectedLang] = useState("both");
  const [isOpenSelect, setIsOpenSelect] = useState(false);

  const uniqueLanguages = useMemo(() => {
    return Array.from(new Set([sourceLang, targetLang].filter(Boolean)));
  }, [sourceLang, targetLang]);

  const languages = [
    { name: "English", flag: "/flags/english.svg", code: "en-US" },
    { name: "Spanish", flag: "/flags/spanish.svg", code: "es-ES" },
    { name: "French", flag: "/flags/french.svg", code: "fr-FR" },
    { name: "German", flag: "/flags/german.svg", code: "de-DE" },
    { name: "Chinese", flag: "/flags/china.svg", code: "zh-CN" },
    { name: "Mandarin Chinese", flag: "/flags/china.svg", code: "zh-CN" },
    { name: "Japanese", flag: "/flags/japanese.svg", code: "ja-JP" },
    { name: "Italian", flag: "/flags/italian.svg", code: "it-IT" },
    { name: "Korean", flag: "/flags/korean.svg", code: "ko-KR" },
    { name: "Portuguese", flag: "/flags/portugese.svg", code: "pt-PT" },
    { name: "Russian", flag: "/flags/russian.svg", code: "ru-RU" },
    { name: "Arabic", flag: "/flags/arabia.svg", code: "ar-SA" },
    { name: "Hindi", flag: "/flags/india.svg", code: "hi-IN" },
    { name: "Urdu", flag: "/flags/pakistan.svg", code: "ur-PK" },
    { name: "Bengali", flag: "/flags/bengali.svg", code: "bn-BD" },
    { name: "Turkish", flag: "/flags/turkish.svg", code: "tr-TR" },
    { name: "Swahili", flag: "/flags/sawahili.svg", code: "sw-KE" },
    { name: "Vietnamese", flag: "/flags/vietnam.svg", code: "vi-VN" },
    { name: "Thai", flag: "/flags/thai.svg", code: "th-TH" },
    { name: "Punjabi", flag: "/flags/india.svg", code: "pa-IN" },
    // { name: "Malay", flag: "/flags/malaysia.svg", code: "ms-MY" },
    { name: "Greek", flag: "/flags/greek.svg", code: "el-GR" },
    { name: "Marshallese", flag: "/flags/marshal.svg", code: "mh-MH" },
    { name: "Tamil", flag: "/flags/india.svg", code: "ta-IN" },
    { name: "Telugu", flag: "/flags/india.svg", code: "te-IN" },
    { name: "Kannada", flag: "/flags/india.svg", code: "kn-IN" },
    { name: "Gujarati", flag: "/flags/india.svg", code: "gu-IN" },
    { name: "Marathi", flag: "/flags/india.svg", code: "mr-IN" },
    { name: "Oriya (Odia)", flag: "/flags/india.svg", code: "or-IN" },
    { name: "Burmese", flag: "/flags/burmese.svg", code: "my-MM" },
    { name: "Pashto", flag: "/flags/pakistan.svg", code: "ps-AF" },
    { name: "Farsi (Persian)", flag: "/flags/iran.svg", code: "fa-IR" },
    { name: "Amharic", flag: "/flags/amharic.svg", code: "am-ET" },
    { name: "Nepali", flag: "/flags/nepali.svg", code: "ne-NP" },
    { name: "Sinhala (Sinhalese)", flag: "/flags/srilanka.svg", code: "si-LK" },
    { name: "Zulu", flag: "/flags/zulu.svg", code: "zu-ZA" },
    { name: "Somali", flag: "/flags/somali.svg", code: "so-SO" },
    { name: "Yoruba", flag: "/flags/niger.svg", code: "yo-NG" },
    { name: "Igbo", flag: "/flags/niger.svg", code: "ig-NG" },
    // { name: "Kazakh", flag: "/flags/kazakhstan.svg", code: "kk-KZ" },
    { name: "Tigrinya", flag: "/flags/tigrin.svg", code: "ti-ER" },
    { name: "Maithili", flag: "/flags/india.svg", code: "mai-IN" },
    { name: "Javanese", flag: "/flags/javanese.svg", code: "jv-ID" },
    { name: "Madurese", flag: "/flags/javanese.svg", code: "mad-ID" },
    { name: "Hmong", flag: "/flags/english.svg", code: "hmn-LA" },
    { name: "Assamese", flag: "/flags/india.svg", code: "as-IN" },
    { name: "Cebuano (Bisaya)", flag: "/flags/philip.svg", code: "ceb-PH" },
    { name: "Hausa", flag: "/flags/niger.svg", code: "ha-NG" },
    { name: "Ukrainian", flag: "/flags/ukrane.svg", code: "uk-UA" },
  ];


  const handleToggleSelect = () => {
    setIsOpenSelect((prev) => !prev);
  };

  const getLanguageDetails = (langCode: string) => {
    return languages.find((lang) => lang.name === langCode);
  };

  const getSourceFlagByCode = (code: string | null) => {
    return languages.find((lang) => lang.name === code)?.flag || "/flags/english.svg";
  };

  const handleFilterBasedOnLang = (langCode: string) => {
    if (langCode === selectedLang && langCode !== "both") {
      setSelectedLang(""); // If the same language is selected, clear the filter
    } else {
      setSelectedLang(langCode); // Otherwise, apply the new language
    }
    setIsOpenSelect(false); // Close the dropdown after selecting
  };

  const getLanguageCode = (lang: string) => lang.split("-")[0];

  const filteredItems = items.filter((conversationItem) => {
    if (selectedLang === "both") return true;  // Show all for both languages
    const conversationLang = conversationItem.role === "user" ? sourceLang : targetLang;
    return conversationLang === selectedLang;
  });

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`; // Format as MM:SS
  }


  return (
    <div className="h-screen w-screen overflow-hidden bg-gradient-to-b from-white to-sky-50 flex flex-col relative">
      <div className="flex-shrink-0 z-10">
        <Header showBackButton />
      </div>
      {user.user_type === 'FREE' && (
        <div className="mb-4 p-5 flex flex-row justify-start items-center gap-1 absolute top-[70px]">
          <h1 className="text-lg font-semibold text-gray-800">
            Demo Time:
          </h1>
          <div className="text-3xl font-bold text-teal-600">
            {formatTime(remainingTime)}
          </div>
        </div>
      )}

      <div className="h-[25rem] relative flex flex-col items-center justify-center top-6">
        <div className='mt-12 md:mt-2' />
        <Button
          disabled={(remainingTime <= 0 && user.user_type === 'FREE')}
          label={isConnected ? 'Stop Conversation' : 'Start Conversation'}
          iconPosition={isConnected ? 'end' : 'start'}
          buttonStyle={isConnected ? 'regular' : 'action'}
          onClick={
            isConnected ? disconnectConversation : connectConversation
          }
        />
        <div className='my-1' />
        <div className='relative flex flex-col justify-center items-center mt-2'>
          {(isConnected && (isRecording || canPushToTalk)) && (
            <>
              <MicButton
                label={isRecording ? 'release to send' : 'push to talk'}
                buttonStyle={isRecording ? 'alert' : 'regular'}
                disabled={!isConnected || !canPushToTalk}
                onPressStart={startRecording}
                onPressEnd={stopRecording}
                showRipple={isConnected && (isRecording || !canPushToTalk)}
              />
              {canPushToTalk ? (
                <p className='mt-1 text-[#79AEB5]'>Hold and press to speak</p>
              ) : (
                <p className='mt-1 text-[#79AEB5]'>Harmoni is listening</p>
              )}
            </>
          )}
        </div>
        <div className='relative flex flex-col justify-center items-center mt-2'>
          {isConnected && !canPushToTalk && (
            <>
              <MicButton
                label={isRecording ? 'release to send' : 'push to talk'}
                buttonStyle={isRecording ? 'alert' : 'regular'}
                disabled={!isConnected || !canPushToTalk}
                onPressStart={startRecording}
                onPressEnd={stopRecording}
                showRipple={isConnected && (isRecording || !canPushToTalk)}
              />
              {canPushToTalk ? (
                <p className='mt-1 text-[#79AEB5]'>Hold and press to speak</p>
              ) : (
                <p className='mt-1 text-[#79AEB5]'>Harmoni is listening</p>
              )}
            </>
          )}
        </div>
        <Toggle
          defaultValue={false}
          labels={['Push to Talk', 'Always Listening']}
          values={['none', 'server_vad']}
          onChange={(_, value) => changeTurnEndType(value)}
        />

      </div>
      <div className="relative flex-grow overflow-x-hidden bg-white flex flex-col items-center justify-start transition-opacity">
        <div className="mb-[40px] w-full max-w-[100%]">
          <div className="flex flex-col h-screen">
            {/* Header - Stays at the top */}
            <div className="sticky top-0 z-10 bg-white shadow-md">
              <div className="flex items-center justify-start md:justify-between my-[14px] px-4">
                <p className="w-[142px] h-[30px] text-[20px] font-medium leading-[30px] text-[#000] text-start">
                  Transcription
                </p>
                {/* Language Select Dropdown */}
                <div className="relative language-select">
                  <button
                    onClick={handleToggleSelect}
                    className="flex items-center gap-1 cursor-pointer w-[160px] ml-4"
                  >
                    {getLanguageDetails(selectedLang)?.name && (
                      <img
                        src={getSourceFlagByCode(selectedLang)}
                        alt={`${selectedLang} Flag`}
                        width={14}
                        height={14}
                      />
                    )}
                    <span className="text-[13px] font-medium text-black">
                      {getLanguageDetails(selectedLang)?.name || "Both Languages"}
                    </span>
                    <ChevronDown color="black" size={12} />
                  </button>

                  {isOpenSelect && (
                    <div className="absolute top-full mt-1 bg-white border border-gray-200 border-t-0 shadow-lg rounded-br-[13.5px] rounded-bl-[13.5px] w-[175px] z-10">
                      <ul className="py-1">
                        <li
                          className="flex items-center gap-1 px-4 py-2 text-sm text-gray-800 cursor-pointer hover:bg-gray-100 text-[13px]"
                          onClick={() => handleFilterBasedOnLang("both")}
                        >
                          Both Languages
                        </li>
                        {uniqueLanguages.map((langCode) => {
                          const language = getLanguageDetails(langCode || "");
                          return language ? (
                            <li
                              key={langCode}
                              className="flex items-center gap-1 px-4 py-2 text-sm text-gray-800 cursor-pointer hover:bg-gray-100 text-[13px]"
                              onClick={() => handleFilterBasedOnLang(langCode || "")}
                            >
                              <img
                                src={language.flag}
                                alt={`${language.name} Flag`}
                                width={14}
                                height={14}
                              />
                              {language.name}
                            </li>
                          ) : null;
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Scrollable Conversation Content */}
            <div className="flex-grow bg-gray-50 px-4">
              <div className="mb-[12px] flex flex-col">
                {filteredItems.map((conversationItem) => (
                  <div
                    key={conversationItem.id}
                    className="w-full bg-[#fcfcfc] rounded-[11px] p-[12px] my-[6px]"
                  >
                    {/* Display speaker role */}
                    <div className="flex justify-between items-center mb-2">
                      <p
                        className={`text-[16px] font-medium ${conversationItem.role === "user"
                          ? "text-[#000]"
                          : "text-[#2B96A5]"
                          }`}
                      >
                        {/* {conversationItem.role === "user"
                          ? `${getLanguageCode(sourceLang || "")}`
                          : `${getLanguageCode(targetLang || "")}`} */}
                        {conversationItem.role === "user"
                          ? `User`
                          : `Harmoni`}
                      </p>
                      <button
                        className="text-red-500 hover:text-red-700"
                        onClick={() => deleteConversationItem(conversationItem.id)}
                      >
                        <X />
                      </button>
                    </div>

                    {/* Display message or transcript */}
                    <div className="text-[14px] text-[#000]">
                      {conversationItem.type === "function_call_output" && (
                        <div>{conversationItem.formatted.output}</div>
                      )}

                      {!!conversationItem.formatted.tool && (
                        <div>
                          {conversationItem.formatted.tool.name}(
                          {conversationItem.formatted.tool.arguments})
                        </div>
                      )}

                      {!conversationItem.formatted.tool &&
                        conversationItem.role === "user" && (
                          <div>
                            {/* User transcript */}
                          </div>
                        )}

                      {!conversationItem.formatted.tool &&
                        conversationItem.role === "assistant" && (
                          <div>
                            {conversationItem.formatted.transcript ||
                              conversationItem.formatted.text ||
                              "(truncated)"}
                          </div>
                        )}
                    </div>

                    {/* Display audio playback if available */}
                    {conversationItem.formatted.file && (
                      <audio
                        src={conversationItem.formatted.file.url}
                        controls
                        className="mt-2 w-full"
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
