import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../context/AppContext";

const ProtectedRoute = ({ children, requiredType }: { children: JSX.Element; requiredType: string }) => {
    const { isAuth, user } = useUser();

    if (!isAuth) {
        // Redirect unauthenticated users to the login page
        return <Navigate to="/" replace />;
    }

    if (user.user_type !== requiredType) {
        // Redirect users without the required role to the home page
        return <Navigate to="/home" replace />;
    }

    // Render the protected route for authorized users
    return children;
};

export default ProtectedRoute;
