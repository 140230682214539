import { useState } from "react";
import Header from "../../components/header";
import LangSelect from "../../components/lang-select";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../context/AppContext";

export function Home() {
    const [showPopup, setShowPopup] = useState(false);
    const [sourceLang, setSourceLang] = useState("English");
    const [targetLang, setTargetLang] = useState("Spanish");
    const { user } = useUser();
    const navigate = useNavigate()

    if (!user) {
        // Handle the case when user is not logged in yet
        return <div>Loading...</div>; // or something else
    }
    const togglePopup = () => {
        setShowPopup(!showPopup);
    };


    const handleClick = () => {
        if (!user) {
            alert("User data is not available. Please log in.");
            return;
        }

        if ((user.time > 0 && user.user_type === 'FREE') || user.user_type === 'PREMIUM' || user.user_type === 'ENTERPRISE') {
            navigate(`/console?src-lang=${sourceLang}&targ-lang=${targetLang}`);
            togglePopup();
        } else {
            alert("Your time has run out! Please purchase more time to continue.");
        }
    };

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`; // Format as MM:SS
    }


    return (
        <div className="h-screen w-screen overflow-hidden bg-gradient-to-b from-white to-sky-50 flex flex-col relative">
            <div className="flex-shrink-0 z-10">
                <Header showBackButton={false} />
                {user.user_type === 'FREE' && (
                    <div className="mb-4 p-5 flex flex-row justify-start items-center gap-1 absolute top-[70px]">
                        <h1 className="text-lg font-semibold text-gray-800">
                            Demo Time:
                        </h1>
                        <div className="text-3xl font-bold text-teal-600">
                            {formatTime(user.time)}
                        </div>
                    </div>
                )}
                {/* <TimeBanner showBackButton={false} /> */}
            </div>
            {/* Main Content Section */}
            <div className={`flex-grow overflow-y-auto overflow-x-hidden bg-white flex justify-center items-center flex-col transition-opacity ${showPopup ? "opacity-20 pointer-events-none" : "opacity-100"}`}>
                <img
                    src="/home-img.png"
                    alt="harmoni"
                    className="md:w-80 w-[80%] object-contain"
                />
                <img
                    src="/create-room.svg"
                    alt="harmoni"
                    className="object-contain cursor-pointer"
                    onClick={togglePopup}
                />
                <p className="text-[#79AEB5] text-lg font-medium text-center w-[70%]">
                    Tap Here to Start New Conversation with Harmoni
                </p>
            </div>

            {showPopup && (
                <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-[90%] md:w-[50%] flex flex-col items-center text-center">
                        <img src="/pop-1.svg" alt="harmoni" className="mt-2 mb-5" />
                        <h2 className="text-[20px] text-[#08373E] mb-4">
                            Harmoni speaks English by default.
                        </h2>
                        <p className="text-black mx-[10px]">
                            Speak to Harmony like a real person, she can understand almost all languages and can be instructed or communicated with to modify her behavior.
                        </p>
                        <LangSelect
                            sourceLang={sourceLang}
                            setSourceLang={setSourceLang}
                            targetLang={targetLang}
                            setTargetLang={setTargetLang}
                        />
                        <div className="flex gap-4 flex-col">
                            <button
                                className="bg-[#0D666E] text-white text-lg font-medium px-6 py-2 rounded-full shadow-md hover:scale-105 transition-transform"
                                onClick={handleClick}
                            >
                                Confirm
                            </button>
                            <button
                                className="px-4 py-2 bg-transparent text-[#08373E] rounded-lg hover:bg-gray-400 transition-colors"
                                onClick={togglePopup}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
