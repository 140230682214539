// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyA_q44_27LTYax2X7k4jhR3IFWa-7ptd2g",
    authDomain: "harmoni-441521.firebaseapp.com",
    projectId: "harmoni-441521",
    storageBucket: "harmoni-441521.firebasestorage.app",
    messagingSenderId: "485783353493",
    appId: "1:485783353493:web:4ee1d16f9cbf09c897039c",
    measurementId: "G-9737CDPM27"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

// Export Firebase Auth
export const auth = getAuth(app);

export default app;
