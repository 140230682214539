import { signOut } from "firebase/auth";
import { ArrowLeft, LogOut } from "react-feather"
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../context/AppContext";

const Header = ({ showBackButton }: { showBackButton: boolean }) => {
    const { user } = useUser()

    const navigate = useNavigate()
    const handleLogout = async () => {
        try {
            await signOut(auth);
            // console.log("User logged out");
        } catch (error) {
            console.error("Error logging out:", error);
        }
    };

    return (
        <div className="w-full h-[70px] bg-[#0d5c67] my-0 flex items-center justify-between px-6">
            <div className="flex flex-row justify-center items-center">
                {showBackButton && (
                    <button
                        onClick={() => window.history.back()} // Example back button functionality
                        className="ml-0 md:ml-5 bg-transparent text-white text-sm font-medium rounded hover:bg-[#1e7893] transition"
                    >
                        <ArrowLeft color="white" size={35} />
                    </button>
                )}
                <div className="w-[95px] ml-[21px] h-[33px] font-['Poppins'] text-[22px] font-medium leading-[33px] text-center cursor-pointer" onClick={() => navigate('/home')}>
                    <span className="text-[22px] font-normal leading-[33px] text-white uppercase">
                        H
                    </span>
                    <span className="text-[22px] font-normal leading-[33px] text-white">
                        armoni
                    </span>
                </div>
            </div>
            <div className="flex flex-row mr-3 justify-center items-center">
                {user.user_type === 'ENTERPRISE' && (
                    <a className="text-[14px] md:text-[17px] px-[13px] py-[3px] mr-[15px] bg-[#fff] text-[#000] rounded-[7px] cursor-pointer" onClick={() => navigate('/enterprise-dash')}>Users</a>
                )}
                <a className="text-[14px] md:text-[17px] px-[13px] py-[3px] mr-[15px] bg-[#A8EBF4] text-[#08373E] rounded-[7px] cursor-pointer" href="https://forms.gle/gMBc5eMgW92TP1rt6" target="_blank">Feedback</a>
                <LogOut color="white" className="cursor-pointer" onClick={handleLogout} />
            </div>
        </div>
    )
}
export default Header