import React, { useState } from "react";
import { collection, query, where, getDocs, doc, setDoc, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { ArrowLeft } from "react-feather";

const AddEnterpriseUsers = () => {
    const [email, setEmail] = useState("");
    const [status, setStatus] = useState("");

    const handleAddUser = async (e: React.FormEvent) => {
        e.preventDefault();

        // Validate email format
        if (!email.includes("@")) {
            setStatus("Invalid email format.");
            return;
        }

        // Automatically assign "premium" for enterprise users
        const domain = email.split("@")[1];
        const userType = domain === "naomedical.com" ? "PREMIUM" : "FREE";

        try {
            const usersCollectionRef = collection(db, "users");

            // Check if the email already exists in Firestore
            const emailQuery = query(usersCollectionRef, where("email", "==", email));
            const querySnapshot = await getDocs(emailQuery);

            if (!querySnapshot.empty) {
                // If the email exists, update the existing document
                const docRef = querySnapshot.docs[0].ref; // Get the first matching document reference
                await setDoc(docRef, { user_type: userType }, { merge: true }); // Merge to update only `user_type`
                setStatus(`User with email ${email} updated to ${userType}.`);
            } else {
                // If the email does not exist, create a new document
                await addDoc(usersCollectionRef, {
                    email,
                    user_type: userType,
                    created_at: new Date().toISOString(),
                });
                setStatus(`User with email ${email} added as ${userType}.`);
            }

            setEmail(""); // Clear the input field
        } catch (error) {
            console.error("Error adding or updating user:", error);
            setStatus("Failed to add or update user. Please try again.");
        }
    };

    return (
        <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md justify-center h-screen">
            <button
                onClick={() => window.history.back()} // Example back button functionality
                className="ml-5 bg-transparent text-white text-sm font-medium rounded hover:bg-[#1e7893] transition absolute left-0 top-10"
            >
                <ArrowLeft color="black" size={35} />
            </button>
            <h1 className="text-lg font-semibold text-gray-800 mb-4">
                Add Enterprise Users
            </h1>
            <form onSubmit={handleAddUser} className="w-full max-w-sm">
                <input
                    type="email"
                    className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
                    placeholder="Enter user email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <button
                    type="submit"
                    className="w-full px-4 py-2 bg-teal-600 text-white font-semibold rounded-lg hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500"
                >
                    Add User
                </button>
            </form>
            {status && <p className="mt-4 text-sm text-gray-600">{status}</p>}
        </div>
    );
};

export default AddEnterpriseUsers;
