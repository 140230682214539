import { useState, useEffect, useCallback, useRef } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useUser } from "../../context/AppContext";

export const useTimer = () => {
    const { user, setUser, isAuth } = useUser();
    const [remainingTime, setRemainingTime] = useState<number>(user?.time || 0);
    const [isRunning, setIsRunning] = useState(false);

    const isRunningRef = useRef(false);
    const startTimeRef = useRef<number | null>(null);

    const startTimer = useCallback(() => {
        if (isRunningRef.current) {
            // console.log("Timer already running");
            return;
        }

        if (!user?.time || user.time <= 0) {
            // alert("No time remaining. Please purchase more time.");
            return;
        }

        startTimeRef.current = Date.now();
        isRunningRef.current = true;
        setIsRunning(true);
    }, [user]);

    const stopTimer = useCallback(async () => {
        if (!isRunningRef.current || startTimeRef.current === null) {
            console.error("Cannot stop timer: Timer is not running or startTime is null.");
            return;
        }

        isRunningRef.current = false;
        setIsRunning(false);

        const elapsedTime = Math.floor((Date.now() - startTimeRef.current!) / 1000);
        const updatedTime = Math.max(user.time - elapsedTime, 0);

        setRemainingTime(updatedTime);
        startTimeRef.current = null;

        const updatedUser = { ...user, time: updatedTime };
        setUser(updatedUser);

        try {
            if (!isAuth?.uid) {
                console.error("User UID is undefined. Cannot update Firestore.");
                // alert("User is not properly authenticated.");
                return;
            }

            const userDocRef = doc(db, "users", isAuth.uid);
            await updateDoc(userDocRef, { time: updatedTime });

            sessionStorage.setItem("user", JSON.stringify(updatedUser));
            // alert("Time successfully updated!");
        } catch (error) {
            console.error("Failed to update Firestore:", error);
            // alert("Error updating time. Please try again.");
        }
    }, [user, setUser, isAuth?.uid]);

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (isRunning && startTimeRef.current !== null) {
            timer = setInterval(() => {
                const elapsed = Math.floor((Date.now() - startTimeRef.current!) / 1000);
                const newRemainingTime = Math.max((user?.time || 0) - elapsed, 0);

                setRemainingTime(newRemainingTime);

                if (newRemainingTime <= 0) {
                    // console.log("Time ran out. Stopping timer...");
                    stopTimer();
                }
            }, 1000);
        }

        return () => {
            if (timer) clearInterval(timer);
        };
    }, [isRunning, user?.time, stopTimer]);

    return { remainingTime, isRunning, startTimer, stopTimer };
};
