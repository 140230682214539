import { ripples } from 'ldrs';

ripples.register();

export function MicButton({
    label = 'Okay',
    buttonStyle = 'regular',
    showRipple = false,
    onPressStart,
    onPressEnd,
    ...rest
}) {
    const handlePressStart = (event) => {
        event.preventDefault(); // Prevent default behavior
        if (onPressStart) onPressStart(event); // Trigger parent's start handler
    };

    const handlePressEnd = (event) => {
        event.preventDefault(); // Prevent default behavior
        if (onPressEnd) onPressEnd(event); // Trigger parent's end handler
    };

    const handleContextMenu = (event) => {
        event.preventDefault(); // Disable context menu
    };

    return (
        <button
            className={`relative flex justify-center items-center cursor-pointer button-style-${buttonStyle}`}
            onMouseDown={handlePressStart}
            onMouseUp={handlePressEnd}
            onTouchStart={handlePressStart}
            onTouchEnd={handlePressEnd}
            onTouchCancel={handlePressEnd} // Ensures stopping on touch cancellation
            onContextMenu={handleContextMenu} // Prevents right-click menu
            {...rest}
        >
            <img
                src="/sess-button.svg"
                alt="Session Button"
                width={70}
                height={70}
            />
            {showRipple ? (
                <div className="absolute">
                    <l-ripples size="80" speed="2" color="red"></l-ripples>
                </div>
            ) : (
                <img
                    src="/mic-fill.svg"
                    alt="Microphone Icon"
                    width={55}
                    height={55}
                    className="absolute"
                />
            )}
        </button>
    );
}
