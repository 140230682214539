import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ConsolePage } from "./pages/ConsolePage";
import OnboardingPage from "./pages/onboarding/OnBoarding";
import { Home } from "./pages/home/HomePage";
import AddEnterpriseUsers from "./pages/enterprise/AddEnterpriseUsers";
import { useUser } from "./context/AppContext";
import ProtectedRoute from "./utils/ProtectedRoute";

function App() {
  const { loading, isAuth } = useUser();

  if (loading) return <div>Loading...</div>;

  return (
    <Router>
      <div data-component="App">
        <Routes>
          <Route
            path="/test"
            element={<div>404 - Page Not Found</div>}
          />
          <Route
            path="/"
            element={
              isAuth ? <Navigate to="/app" replace /> : <OnboardingPage />
            }
          />
          <Route
            path="/app"
            element={isAuth ? <Home /> : <Navigate to="/" replace />}
          />
          <Route
            path="/console"
            element={isAuth ? <ConsolePage /> : <Navigate to="/" replace />}
          />
          <Route
            path="/enterprise-dash"
            element={
              <ProtectedRoute requiredType="ENTERPRISE">
                <AddEnterpriseUsers />
              </ProtectedRoute>
            }
          />
          <Route
            path="*"
            element={<div>404 - Page Not Found</div>}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
