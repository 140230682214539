import { useEffect, useState } from "react";
import { ChevronDown } from "react-feather";

const languagesSource = [
    { name: "English", flag: "/flags/english.svg", code: "en-US" },
    { name: "Spanish", flag: "/flags/spanish.svg", code: "es-ES" },
    { name: "French", flag: "/flags/french.svg", code: "fr-FR" },
    { name: "German", flag: "/flags/german.svg", code: "de-DE" },
    { name: "Chinese", flag: "/flags/china.svg", code: "zh-CN" },
    { name: "Mandarin Chinese", flag: "/flags/china.svg", code: "zh-CN" },
    { name: "Japanese", flag: "/flags/japanese.svg", code: "ja-JP" },
    { name: "Italian", flag: "/flags/italian.svg", code: "it-IT" },
    { name: "Korean", flag: "/flags/korean.svg", code: "ko-KR" },
    { name: "Portuguese", flag: "/flags/portugese.svg", code: "pt-PT" },
    { name: "Russian", flag: "/flags/russian.svg", code: "ru-RU" },
    { name: "Arabic", flag: "/flags/arabia.svg", code: "ar-SA" },
    { name: "Hindi", flag: "/flags/india.svg", code: "hi-IN" },
    { name: "Urdu", flag: "/flags/pakistan.svg", code: "ur-PK" },
    { name: "Bengali", flag: "/flags/bengali.svg", code: "bn-BD" },
    { name: "Turkish", flag: "/flags/turkish.svg", code: "tr-TR" },
    { name: "Swahili", flag: "/flags/sawahili.svg", code: "sw-KE" },
    { name: "Vietnamese", flag: "/flags/vietnam.svg", code: "vi-VN" },
    { name: "Thai", flag: "/flags/thai.svg", code: "th-TH" },
    { name: "Punjabi", flag: "/flags/india.svg", code: "pa-IN" },
    // { name: "Malay", flag: "/flags/malaysia.svg", code: "ms-MY" },
    { name: "Greek", flag: "/flags/greek.svg", code: "el-GR" },
    { name: "Marshallese", flag: "/flags/marshal.svg", code: "mh-MH" },
    { name: "Tamil", flag: "/flags/india.svg", code: "ta-IN" },
    { name: "Telugu", flag: "/flags/india.svg", code: "te-IN" },
    { name: "Kannada", flag: "/flags/india.svg", code: "kn-IN" },
    { name: "Gujarati", flag: "/flags/india.svg", code: "gu-IN" },
    { name: "Marathi", flag: "/flags/india.svg", code: "mr-IN" },
    { name: "Oriya (Odia)", flag: "/flags/india.svg", code: "or-IN" },
    { name: "Burmese", flag: "/flags/burmese.svg", code: "my-MM" },
    { name: "Pashto", flag: "/flags/pakistan.svg", code: "ps-AF" },
    { name: "Farsi (Persian)", flag: "/flags/iran.svg", code: "fa-IR" },
    { name: "Amharic", flag: "/flags/amharic.svg", code: "am-ET" },
    { name: "Nepali", flag: "/flags/nepali.svg", code: "ne-NP" },
    { name: "Sinhala (Sinhalese)", flag: "/flags/srilanka.svg", code: "si-LK" },
    { name: "Zulu", flag: "/flags/zulu.svg", code: "zu-ZA" },
    { name: "Somali", flag: "/flags/somali.svg", code: "so-SO" },
    { name: "Yoruba", flag: "/flags/niger.svg", code: "yo-NG" },
    { name: "Igbo", flag: "/flags/niger.svg", code: "ig-NG" },
    // { name: "Kazakh", flag: "/flags/kazakhstan.svg", code: "kk-KZ" },
    { name: "Tigrinya", flag: "/flags/tigrin.svg", code: "ti-ER" },
    { name: "Maithili", flag: "/flags/india.svg", code: "mai-IN" },
    { name: "Javanese", flag: "/flags/javanese.svg", code: "jv-ID" },
    { name: "Madurese", flag: "/flags/javanese.svg", code: "mad-ID" },
    { name: "Hmong", flag: "/flags/english.svg", code: "hmn-LA" },
    { name: "Assamese", flag: "/flags/india.svg", code: "as-IN" },
    { name: "Cebuano (Bisaya)", flag: "/flags/philip.svg", code: "ceb-PH" },
    { name: "Hausa", flag: "/flags/niger.svg", code: "ha-NG" },
    { name: "Ukrainian", flag: "/flags/ukrane.svg", code: "uk-UA" },
];

const languagesTarget = [
    { name: "English", flag: "/flags/english.svg", code: "en-US" },
    { name: "Spanish", flag: "/flags/spanish.svg", code: "es-ES" },
    { name: "French", flag: "/flags/french.svg", code: "fr-FR" },
    { name: "German", flag: "/flags/german.svg", code: "de-DE" },
    { name: "Chinese", flag: "/flags/china.svg", code: "zh-CN" },
    { name: "Mandarin Chinese", flag: "/flags/china.svg", code: "zh-CN" },
    { name: "Japanese", flag: "/flags/japanese.svg", code: "ja-JP" },
    { name: "Italian", flag: "/flags/italian.svg", code: "it-IT" },
    { name: "Korean", flag: "/flags/korean.svg", code: "ko-KR" },
    { name: "Portuguese", flag: "/flags/portugese.svg", code: "pt-PT" },
    { name: "Russian", flag: "/flags/russian.svg", code: "ru-RU" },
    { name: "Arabic", flag: "/flags/arabia.svg", code: "ar-SA" },
    { name: "Hindi", flag: "/flags/india.svg", code: "hi-IN" },
    { name: "Urdu", flag: "/flags/pakistan.svg", code: "ur-PK" },
    { name: "Bengali", flag: "/flags/bengali.svg", code: "bn-BD" },
    { name: "Turkish", flag: "/flags/turkish.svg", code: "tr-TR" },
    { name: "Swahili", flag: "/flags/sawahili.svg", code: "sw-KE" },
    { name: "Vietnamese", flag: "/flags/vietnam.svg", code: "vi-VN" },
    { name: "Thai", flag: "/flags/thai.svg", code: "th-TH" },
    { name: "Punjabi", flag: "/flags/india.svg", code: "pa-IN" },
    // { name: "Malay", flag: "/flags/malaysia.svg", code: "ms-MY" },
    { name: "Greek", flag: "/flags/greek.svg", code: "el-GR" },
    { name: "Marshallese", flag: "/flags/marshal.svg", code: "mh-MH" },
    { name: "Tamil", flag: "/flags/india.svg", code: "ta-IN" },
    { name: "Telugu", flag: "/flags/india.svg", code: "te-IN" },
    { name: "Kannada", flag: "/flags/india.svg", code: "kn-IN" },
    { name: "Gujarati", flag: "/flags/india.svg", code: "gu-IN" },
    { name: "Marathi", flag: "/flags/india.svg", code: "mr-IN" },
    { name: "Oriya (Odia)", flag: "/flags/india.svg", code: "or-IN" },
    { name: "Burmese", flag: "/flags/burmese.svg", code: "my-MM" },
    { name: "Pashto", flag: "/flags/pakistan.svg", code: "ps-AF" },
    { name: "Farsi (Persian)", flag: "/flags/iran.svg", code: "fa-IR" },
    { name: "Amharic", flag: "/flags/amharic.svg", code: "am-ET" },
    { name: "Nepali", flag: "/flags/nepali.svg", code: "ne-NP" },
    { name: "Sinhala (Sinhalese)", flag: "/flags/srilanka.svg", code: "si-LK" },
    { name: "Zulu", flag: "/flags/zulu.svg", code: "zu-ZA" },
    { name: "Somali", flag: "/flags/somali.svg", code: "so-SO" },
    { name: "Yoruba", flag: "/flags/niger.svg", code: "yo-NG" },
    { name: "Igbo", flag: "/flags/niger.svg", code: "ig-NG" },
    // { name: "Kazakh", flag: "/flags/kazakhstan.svg", code: "kk-KZ" },
    { name: "Tigrinya", flag: "/flags/tigrin.svg", code: "ti-ER" },
    { name: "Maithili", flag: "/flags/india.svg", code: "mai-IN" },
    { name: "Javanese", flag: "/flags/javanese.svg", code: "jv-ID" },
    { name: "Madurese", flag: "/flags/javanese.svg", code: "mad-ID" },
    { name: "Hmong", flag: "/flags/english.svg", code: "hmn-LA" },
    { name: "Assamese", flag: "/flags/india.svg", code: "as-IN" },
    { name: "Cebuano (Bisaya)", flag: "/flags/philip.svg", code: "ceb-PH" },
    { name: "Hausa", flag: "/flags/niger.svg", code: "ha-NG" },
    { name: "Ukrainian", flag: "/flags/ukrane.svg", code: "uk-UA" },
];


const LangSelect = ({ sourceLang, setSourceLang, targetLang, setTargetLang }: any) => {
    const [isOpenSource, setIsOpenSource] = useState(false);
    const [isOpenTarget, setIsOpenTarget] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const filteredLanguages = languagesTarget.filter((lang) =>
        lang.name.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const handleSourceLangSelect = (code: string) => {
        setSourceLang(code);
        setIsOpenSource(false);
    };

    const handleTargetLangSelect = (name: string) => {
        setTargetLang(name);
        setIsOpenTarget(false);
    };

    const getFlagByCode = (value: string, list: any[]) => {
        const lang = list.find((lang) => lang.name === value);
        return lang ? lang.flag : "/flags/default.svg"; // Provide a fallback if not found
    };

    const handleToggleSource = () => {
        setIsOpenSource((prev) => !prev);
        setIsOpenTarget(false); // Close the target dropdown if open
    };

    // Handle clicks to toggle the target dropdown
    const handleToggleTarget = () => {
        setIsOpenTarget((prev) => !prev);
        setIsOpenSource(false); // Close the source dropdown if open
    };

    // Close dropdowns when clicking outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (!target.closest(".language-select")) {
                setIsOpenSource(false);
                setIsOpenTarget(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="flex flex-row justify-between items-center main-container h-[47px] bg-[rgba(255,255,255,0.026)] rounded-[50px] relative shadow-[0_1px_3px_0_rgba(0,0,0,0.25)] mx-auto my-7">
            {/* Source Language Select */}
            {/* <div className="relative language-select">
                <button onClick={handleToggleSource} className="flex items-center gap-1 cursor-pointer w-[160px] ml-4">
                    <img
                        src={getFlagByCode(sourceLang, languagesSource)}
                        alt="Source Flag"
                        width={14}
                        height={14}
                    />
                    <span className="text-[16px] font-medium text-black">
                        {languagesSource.find((lang) => lang.code === sourceLang)?.name}
                    </span>
                    <ChevronDown color="black" size={12} />
                </button>

                {isOpenSource && (
                    <div className="absolute top-full mt-1 bg-white border border-gray-200 border-t-0 shadow-lg rounded-br-[13.5px] rounded-bl-[13.5px] w-[150px] z-10">
                        <ul className="py-1">
                            {languagesSource.map((lang) => (
                                <li
                                    key={lang.code}
                                    className="flex items-center gap-1 px-4 py-2 text-[18px] text-gray-800 cursor-pointer hover:bg-gray-100"
                                    onClick={() => handleSourceLangSelect(lang.code)}
                                >
                                    <img src={lang.flag} alt={`${lang.name} Flag`} width={14} height={14} />
                                    {lang.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div> */}

            {/* Swap Languages */}
            {/* <div className="w-full flex justify-start items-center cursor-pointer" onClick={handleSwapLanguages}>
                <ArrowLeftRight color="black" size={15} />
            </div> */}

            {/* Target Language Select */}
            <div className="relative language-select">
                <button
                    onClick={handleToggleTarget}
                    className="flex items-center justify-center gap-1 cursor-pointer w-full mr-9"
                >
                    <img
                        src={getFlagByCode(targetLang, languagesTarget)}
                        alt="Target Flag"
                        width={14}
                        height={14}
                    />
                    <span className="text-[16px] font-medium text-black">
                        {targetLang || "English"}
                    </span>
                    <ChevronDown color="black" size={12} />
                </button>

                {isOpenTarget && (
                    <div className="absolute top-full mt-1 bg-white border border-gray-200 border-t-0 shadow-lg rounded-br-[13.5px] rounded-bl-[13.5px] w-[200px] z-10">
                        {/* Search Box */}
                        <div className="p-2">
                            <input
                                type="text"
                                placeholder="Search language..."
                                className="w-full px-2 py-1 border rounded text-sm"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>

                        {/* Language List */}
                        <ul className="py-1 max-h-[200px] overflow-y-auto">
                            {filteredLanguages.map((lang) => (
                                <li
                                    key={lang.name} // Use name as the key
                                    className="flex items-center gap-1 px-4 py-2 text-sm text-gray-800 cursor-pointer hover:bg-gray-100 text-[16px]"
                                    onClick={() => handleTargetLangSelect(lang.name)} // Pass name
                                >
                                    <img src={lang.flag} width={14} height={14} />
                                    {lang.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LangSelect;
