import { useState, useEffect, useRef } from "react";

export function Toggle({
  defaultValue = false,
  values,
  labels,
  onChange = () => { },
}: {
  defaultValue?: string | boolean;
  values?: string[];
  labels?: string[];
  onChange?: (isEnabled: boolean, value: string) => void;
}) {
  if (typeof defaultValue === "string") {
    defaultValue = !!Math.max(0, (values || []).indexOf(defaultValue));
  }

  const leftRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLDivElement>(null);
  const bgRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<boolean>(defaultValue);

  const toggleValue = () => {
    const v = !value;
    const index = +v;
    setValue(v);
    onChange(v, (values || [])[index]);
  };

  useEffect(() => {
    const leftEl = leftRef.current;
    const rightEl = rightRef.current;
    const bgEl = bgRef.current;
    if (leftEl && rightEl && bgEl) {
      if (value) {
        bgEl.style.left = rightEl.offsetLeft + "px";
        bgEl.style.width = rightEl.offsetWidth + "px";
      } else {
        bgEl.style.left = "";
        bgEl.style.width = leftEl.offsetWidth + "px";
      }
    }
  }, [value]);

  return (
    <div className="flex flex-col items-center">
      {/* Toggle Switch */}
      <div
        className="relative flex items-center justify-between w-32 h-8 bg-gray-200 rounded-full p-1 cursor-pointer"
        onClick={toggleValue}
        data-enabled={value.toString()}
      >
        <div
          ref={leftRef}
          className={`flex-1 text-center text-sm font-medium ${!value ? "text-white" : "text-gray-500"
            }`}
        >
          {/* Empty to maintain spacing */}
        </div>
        <div
          ref={rightRef}
          className={`flex-1 text-center text-sm font-medium ${value ? "text-white" : "text-gray-500"
            }`}
        >
          {/* Empty to maintain spacing */}
        </div>
        <div
          ref={bgRef}
          className={`absolute top-1 bottom-1 left-1 w-1/2 bg-[#0D5C67] rounded-full transition-all`}
        ></div>
      </div>

      {/* Labels Below */}
      {labels && (
        <div className="mt-2 flex justify-between w-full text-sm font-medium">
          <span
            className={`${!value ? "text-[#0D5C67]" : "text-gray-500"
              } text-center w-[42px] text-[12px]`}
          >
            {labels[0]}
          </span>
          <span
            className={`${value ? "text-[#0D5C67]" : "text-gray-500"
              } text-center w-[45px] text-[12px]`}
          >
            {labels[1]}
          </span>
        </div>
      )}
    </div>
  );
}
